body {
  font-family: roboto !important;
  /* background: #da1212 !important; */
}
h1 {
  /* -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;</span>
  -webkit-text-stroke-color: #3e3e3e; */
  letter-spacing: 2px;
  /* font-size: 21px; */
  font-size: 25px;
  font-size: 26.58px;
}
h1,
h2,
h3,
h4,
h5 {
  color: #3e3e3e !important;
}
.text-gray {
  color: #3e3e3e;
}
/* main {
  margin: 3.5em 0;
} */
a {
  color: #1169e8;
  text-decoration: none !important;
}
.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  /* width: 16px; */
  width: 20px;
  background: rgb(255, 255, 255);
  animation: lds-facebook 1.2s cubic-bezier(0, 0.1, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.list-group-item,
.box {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 5px;
}

.link,
.tab-nav {
  color: #212529;
  text-decoration: none;
}
.nav-link.active .tab-nav {
  color: #fff !important;
}

.h7 {
  font-size: 0.8em;
}

.fa-2x {
  font-size: 1.5rem;
}
.fa-3x {
  font-size: 1.9rem;
}
.fa-4x {
  font-size: 2.5rem;
}
.fa-2x2 {
  font-size: 1.2rem;
}

/* .front {
  overflow: overlay;
  max-height: 47em; */
/* min-height: 100vh; */
/* background: rgb(255, 255, 255, 0.6);
} */
/* .min-vh-view{
  min-height: 150vh!important;
} */
.front-auth {
  min-height: 48em;
}
footer .nav-pills .nav-link.active,
footer .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ff574a;
  /* border: 1px solid #FF574A !important; */
}

.fontCo {
  font-family: "diagonregular";
}
.text-blue {
  color: #0d6efd !important;
}
.bg-blueLight {
  background-color: #11468f;
}
/* canvas {
  width: 100% !important;
} */
.btn-blue {
  background-color: #1169e8 !important;
  color: white !important;
}
.bg-red {
  background-color: #da1212;
  color: white;
}
.text-red {
  color: #da1212;
}
.bg-white {
  background-color: #fff !important;
}
.text-description {
  color: #7b7b7b !important;
}
.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}
.form-control {
  border: 1px solid #3e3e3e7d !important;
}
.btn-cancel {
  color: #000 !important;
  background-color: #f8f9fa !important;
  border-color: #3e3e3e !important;
}
/* .background {
  background-color: #da1212;
  height: 20em;
  margin-bottom: 7em;
  border-radius: 0 0 5% 5%;
} */
.top-2 {
  top: 12%;
}
.rounded-10 {
  border-radius: 1rem !important;
}
.w-10 {
  width: 10% !important;
}
.w-8 {
  width: 8% !important;
}
.catDescription {
  overflow: hidden;
  width: 10rem;
  height: 0.9rem;
  color: #7b7b7b;
}
.containerNavbar {
  padding-right: var(--bs-gutter-x, 2.75rem);
  padding-left: var(--bs-gutter-x, 2.75rem);
}
.no-gutters {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
/* .form-control:focus {
  outline: 0 !important;
  border-color: initial;
  box-shadow: none;
} */
li {
  list-style: none;
}
.vh-25 {
  height: 25vh !important;
}
.vh-50 {
  height: 50vh !important;
}

/* .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000 !important;
  border-bottom: 1xp solid green !important;
} */
.requiredBefore::before {
  content: " * ";
  color: red;
}
.required::after,
label::after {
  content: " * ";
  color: red;
}
.notRequired > label::after {
  content: "";
}
.p-05 {
  padding: 0.12rem !important;
}
footer ul li {
  padding: 0 !important;
  height: 100%;
}
footer ul li a {
  padding: 0.5rem 1rem;
  display: block;
}
.text-right {
  text-align: right;
}
.no-resize {
  resize: none;
}
#tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #da1212 !important;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: 2px solid #da1212 !important;
}
.nav-tabs {
  border: none;
}
#tabs button {
  border: none;
}
/* .modal.fade{
  -webkit-transition:opacity .3s linear, top .3s ease-out;
  -moz-transition:opacity .3s linear, top .3s ease-out;
  -ms-transition:opacity .3s linear, top .3s ease-out;
  -o-transition:opacity .3s linear, top .3s ease-out;
  transition:opacity .3s linear, top .3s ease-out;
  top:65%;} */

.modal-bottom {
  height: initial !important;
  bottom: 0 !important;
  top: initial !important;
}
.modal-border-top {
  border-top: 7px solid #808080;
}
.modal-border-bottom {
  border-bottom: 1px solid;
}

section {
  background: url(../public/background.png) no-repeat center center;
  background-size: cover;
}
.login {
  background: rgb(255, 255, 255, 0.6);
}
input,
textarea,
select {
  border-radius: 0.75rem !important;
  background: rgb(255, 255, 255, 0.5) !important;
}
.btn,
.alert {
  border-radius: 0.75rem !important;
}
.review {
  border-radius: 0.75rem !important;
  background: #dcd835;
  padding: 1em 0;
}
.border-top {
  border-radius: 0.75rem !important;
}
.btn-danger {
  color: #fff !important;
  background-color: #eb2c1d !important;
  border-color: #eb2c1d !important;
}
.text-red {
  color: #eb2c1d !important;
}
.text-blue {
  color: #1169e8;
}
.bg-blue {
  background-color: #1169e8 !important;
}
.text-gray {
  color: #3e3e3e;
}
.text-text {
  color: #7b7b7b;
}
.fa-google {
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}
.square {
  width: 50px;
  background: white;
  margin: auto;
  border-radius: 5px;
}
.tab-nav {
  color: "white";
  background: "#FF574A";
}
.hcat {
  font-size: 0.85rem;
}
.fa-xl {
  font-size: 8.5rem;
}
.map {
  width: 90vw;
  /* margin-left: 10vw; */
  margin: auto;
  height: 60vh;
}

.infoContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
}
.arrow {
  padding: 0 !important;
  position: relative;
}
.fixed-top {
  position: sticky !important;
}
.right-2 {
  right: 2.2vh;
}
.publication {
  height: 80px;
}
a {
  color: #3e3e3e !important;
}

/* modales */
.modal-dialog {
  margin: 0 1.5rem !important;
}
.modal-content {
  border-radius: 16px !important;
}
.modal-content .modal-header {
  /* background-color: red; */
  border: none;
  /* justify-content: center; */
  /* color: white; */
}

.modal-footer {
  gap: 0.5rem !important;
  display: grid !important;
  border: none !important;
  justify-content: initial !important;
}
.border-btn {
  border: 1px solid #6c6c6c !important;
}
.modal-body {
  padding: 1rem !important;
}
.btn-1rem {
  bottom: -1rem !important;
}
.bg-details {
  background-size: cover;
  height: 230px;
  position: absolute;
}
.s-small {
  font-size: 0.875em !important;
  position: absolute;
  right: -0.3em;
  bottom: 0.2em;
  margin: 0;
  padding: 0 0.4em;
}
